.banner-bg {
    width: 100%;
    height: 810px;
    top: -90px;
    background-color: #FFF9F8;
    position: relative;
    overflow: hidden;
}

.banner-bg-image {
    position: absolute;
    right: 0;
    height: 100%;
    mask-image: linear-gradient(to right, transparent 5%, black 30%);
}

.banner-headline-text-container {
    position: relative;
    top: 65%;
    padding: 1em calc(1em + 5vw);
    transform: translateY(-80%);
    width: 50%;
}

.banner-headline-title {
    color: var(--text-primary); 
    margin: 0;
    font-size: 48px;
    font-style: normal;
    font-weight: 800;
    line-height: 55px;
    letter-spacing: -0.96px;
}

.banner-headline-subtext {
    color: var(--text-primary); 
    padding-top: 24px;
    padding-bottom: 24px;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.32px;
}

.banner-headline-app-screenshot {
    position: absolute;
    right: 0; 
    bottom: 0;
    display: flex;
    gap: 10px; 
    align-items: flex-end;
}

.app-screenshot-banner {
    z-index: 1;
    margin-right: 60px;
}

.food-bowl {
    position: absolute;
    right: 0; 
    z-index: 2;
}

.store-link {
    cursor: pointer; 
    margin-right: 16px;
    display: inline-block; 
}

@media (max-width: 1250px) {
    .banner-bg {
        height: 1400px;
        width: 100%;
    }

    .banner-bg-image {
        height: 1400px;
    }

    .banner-headline-text-container {
        position: relative; 
        top: 9%;
        width: 100%;
        transform: none; 
        text-align: center;
        max-width: 100%; 
        padding-left: 50px;
        padding-right: 50px;
    }

    .banner-headline-app-screenshot {
        position: absolute; 
    }
}

@media (max-width: 680px) {
    .banner-headline-app-screenshot {
        position: absolute;
        bottom: 0;
        left: 2%;
    }

    .food-bowl {
        visibility: hidden;
    }
}

@media (max-width: 425px) {
    .banner-bg {
        height: 800px;
    }

    .banner-bg-image {
        height: 800px;
    }

    .banner-headline-app-screenshot {
        visibility: hidden;
    }

    .food-bowl {
        visibility: hidden;
    }
}
