.box {
    width: 64px;
    height: 64px;
    border-radius: 12px;
    position: relative;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 24px;
}

.feature-icon {
    position: absolute;
    height: 36px;
    width: 36px;
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
}


.app-functions {
    text-align: center;
    padding: 20px;
    margin-top: 120px;
    margin-bottom: 120px;
}

.title {
    color: var(--text-primary); 
    text-align: center;
    font-size: 30px;
    line-height: 40px;
    margin-bottom: 80px;
}

.app-functions ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 30px;
    flex-wrap: wrap; 
}

.func-stack {
    padding: 20px;
    text-align: center;
    width: 400px;
    box-sizing: border-box; 
}

.stack-title {
    color: var(--text-primary); 
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    margin: 0 0 16px 0;
}

.stack-desc {
    color: var(--text-secondary); 
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px; /* 171.429% */
    letter-spacing: -0.28px;
}

@media (max-width: 600px) {
    .func-stack {
        width: 100%; 
        margin-bottom: 20px; 
    }

    .title {
        margin-bottom: 40px; 
    }
}