body {
  font-family: 'Plus Jakarta Sans', sans-serif;
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; 
}

.content {
  flex: 1;
  margin-top: 120px;
  width: 100%;
}

*, *::before, *::after {
  box-sizing: border-box;
}
