.footer {
    margin-top: 100px;
    position: relative;
}

.footer::before {
    content: "";
    position: absolute;
    top: 60px;
    left: 0;
    right: 0;
    bottom: 0;
    height: calc(100% - 50px);
    background: #668BD1;
    z-index: -1;
}

.footer-banner-title {
    color: var(--White, #FFF);
    font-size: 48px;
    font-weight: 800;
    line-height: 55px; 
    letter-spacing: -0.96px;
}

.footer-banner-subtext {
    color: var(--White, #FFF);
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.32px;
    padding-bottom: 36px;
}

.footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.footer-image-container {
    position: relative;
}

.footer-image-container::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(132, 159, 211, 0) 50%, #668BD1 100%);
    pointer-events: none;
    z-index: 3;
}

.footer-banner-text-container {
    flex: 1;
    max-width: 700px;
    padding-left: 160px;
}

.footer-store-link {
    margin-right: 15px;
}

.footer-image-iphone {
    position: relative;
    z-index: 1;
}

.footer-image-content {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 2;
}

.footer-copyright {
    text-align: center;
    font-size: 14px;
    color: white;
    padding: 10px;
    background: #668BD1; 
}


@media (max-width: 1425px) {
    .footer {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 40px;
        padding-bottom: 60px;
    }

    .footer::before {
        top: 0;
        height: auto;
    }

    .footer-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .footer-banner-text-container {
        text-align: center;
        padding-left: 0;
    }

    .footer-image-container {
        display: none;
    }
}

