.trends-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 150px;
}

.trends-bg {
    position: relative;
    display: flex;
    align-items: center; 
}

.trends-text-container {
    flex: 1;
    width: 445px;
    margin-left: 100px;
}

.trends-screenshot {
    position: relative;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.trends-shell {
    display: block;
}

.trends-chart {
    position: absolute;
    top: 85px;
    z-index: 1;
}

.trends-title {
    color: var(--text-primary);  
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.6px;
}

.trends-subtext {
    color: var(--text-secondary);  
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; 
    letter-spacing: -0.32px;
}

@media (max-width: 1050px) {
    .trends-bg {
        flex-direction: column;
    }
    .trends-text-container {
        margin-top: -50px;
        margin-left: 0;
        text-align: center;
        order: 1; 
    }
    .trends-screenshot {
        margin-top: 50px;
        order: 2; 
    }
}

@media (max-width: 550px) {
    .trends-text-container {
        width: auto;
        margin-left: 0;
        padding: 16px;
        text-align: center;
        order: 1;
    }
}