.footer-navbar {
    display: flex;
    position: relative;
    z-index: 1;
    justify-content: space-between;
    align-items: center;
    padding: 2.5em calc(1em + 5vw); 
    border-top: 0.5px solid rgba(255, 255, 255, 0.2); /* This makes the white color 50% transparent */
    background-color: #668BD1;
  }
  
.footer-navbar ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    display: flex;
    gap: 20px;
  }
  
.footer-navbar li {
    margin: 0;
    display: inline;
  }
  
.footer-navbar a:not(.footer-brand-name) { 
    color: var(--White, #FFF);
    font-size: 16px;
    font-weight: 600;
    letter-spacing: -0.32px;
  }
  
.footer-navbar a {
    text-decoration: none;
    font-weight: 600;
    padding: 0.5em 1em;
    border-radius: 4px;
    align-items: center;
    color: white;
    transition: color 0.3s;
  }
  
.footer-navbar a:not(.footer-brand-name):hover {
    background-color: inherit;
    color: #ddd;
  }
  
.footer-brand-name {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    color: white;
    padding: 60px 0;
  }
  
.footer-brand-name img {
    height: 40px; 
    width: auto;
    margin-right: 14px;
  }

.footer-nav-button {
    font-size: 14px;
    font-weight: 600;
    border: none;
    padding: 16px 24px; 
    border-radius: 10px; 
    margin-left: 24px;
    cursor: pointer;
    background-color: transparent;
    color: white; 
    border: 2px solid white;
  }
  
.footer-navbar a, .footer-nav-button {
    white-space: nowrap;
  }
  
.footer-links {
    display: flex; 
    align-items: center; 
  }

  @media (max-width: 900px) {
    .footer-navbar {
        flex-direction: column;
        align-items: center;
    }

    .footer-navbar ul {
        flex-direction: column;
        gap: 10px;
    }

    .footer-navbar li {
        padding: 10px 0; /* Adding padding to each list item */
    }

    .footer-links {
        flex-direction: column;
        align-items: center;
    }

    .footer-nav-button {
        margin-left: 0;
        margin-top: 10px;
    }
}

