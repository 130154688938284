.export-bg {
    width: 80%;
    height: 300px;
    max-width: 1500px;
    background-color: #F8FDFF;
    border-radius: 24px;
    border: 2px solid #E5F7FA;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    padding: 95px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
    box-sizing: border-box;
}

.export-text-container {
    flex: 1;
    position: absolute;
    left: 95px; 
    top: 35%;
    transform: translateY(-50%);
    width: 40%;
}

.export-title {
    color: var(--text-primary);  
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.6px;
}

.export-subtext {
    color: var(--text-secondary);  
    font-size: 16px;
    font-weight: 500;
    line-height: 28px; 
    letter-spacing: -0.32px;
}

.export-screenshot {
    position: absolute;
    bottom: -200px;
    right: 0;
}

@media (max-width: 1480px) {
    .export-bg {
        padding: 40px;
    }

    .export-text-container {
        left: 40px;
        width: 400px;
    }
}

@media (max-width: 1200px) {
    .export-screenshot {
        right: calc((100vw - 1200px) * 0.8);
    }
}

@media (max-width: 700px) {
    .export-screenshot {
        visibility: hidden;
    }

    .export-bg {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .export-text-container {
        position: static; 
        transform: none; 
        width: 80%;       
        left: auto;       
        top: auto;        
        padding: 0;
    }
}
