.navbar {
  display: flex;
  position: relative;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
  padding: 1em calc(1em + 5vw); 
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  gap: 20px;
}

.navbar li {
  margin: 0;
  display: inline;
}

.navbar a:not(.brand-name) { 
  color: var(--text-primary); 
  font-size: 16px; 
  font-weight: 600; 
}

.navbar a {
  text-decoration: none;
  font-weight: 600;
  padding: 0.5em 1em;
  border-radius: 4px;
  align-items: center;
  color: var(--text-primary);
  transition: color 0.3s;
}

.navbar a:not(.brand-name):hover {
  background-color: inherit;
  color: var(--text-secondary);  
}

.brand-name {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  color: var(--primary);
}

.brand-name img {
  height: 40px; 
  width: auto;
  margin-right: 18px;
}

.nav-button {
  font-size: 14px;
  font-weight: 600;
  border: none;
  padding: 16px 24px; 
  border-radius: 10px; 
  margin-left: 24px;
  cursor: pointer;
  background-color: #E8917B;
  color: #FFFFFF; 
}

.navbar a, .nav-button {
  white-space: nowrap;
}

.links {
  display: flex; 
  align-items: center; 
}

.menu-icon {
  display: none;
  cursor: pointer;
}

.icon {
  font-size: 2rem;
}

@media (max-width: 800px) {
  .navbar {
    padding: 1em 2em;
  }

  .menu-icon {
    display: block;
  }

  .links {
    display: none;
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 70px;
    left: 0;
  }

  .links.show {
    display: flex;
    z-index: 1;
  }

  .nav-links li {
    margin: 15px 0;
  }

  .nav-button {
    margin: 15px auto;
  }
}


