.article-content {
    margin: 0 auto;
}

.article-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.article-header h1 {
    padding: 1em calc(1em + 6vw);
}

.article-header img {
    align-self: flex-end;
    height: 500px;
    mask-image: linear-gradient(to right, transparent, black 70%);
}

.article-body {
    text-align: left;
    margin-top: 80px;
    padding: 1em calc(1em + 6vw);
}

.article-body h1,
.article-body h2,
.article-body h3,
.article-body h4,
.article-body h5,
.article-body h6 {
    color: var(--Black, #323232);
    font-family: Plus Jakarta Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 140%; 
    letter-spacing: -0.4px;
}

.article-body p {
    color: var(--Black, #232323);
    font-family: Plus Jakarta Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 28px; 
    letter-spacing: -0.32px;
}

@media (max-width: 1250px) {
    .article-header img {
        
    }
    
    .article-header h1 {
        width: 100%;
    }

    .article-header {
        display: block;
        text-align: center;
    }
}
