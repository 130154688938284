.download-dialog-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.download-dialog-container {
    background-color: white;
    padding: 80px; 
    border-radius: 8px;
    position: relative;
    max-width: 500px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center; 
}


.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background-color: transparent;
    font-size: 20px;
    cursor: pointer;
}

.dialog-title {
    margin: 0;
    color: var(--Black, #232323);
    text-align: center;
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
    letter-spacing: -0.6px;
    padding-bottom: 24px;
}

.dialog-subtext {
    margin: 10px 0;
    color: var(--Black, #232323);
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: -0.32px;
    padding-bottom: 56px;
}

.store-links {
    display: flex;
    gap: 10px;
}

@media (max-width: 1000px) {
    .download-dialog-container {
        padding: 40px;
        margin: 20px;
    }

    .dialog-title {
        width: auto;
    }

    .dialog-subtext {
        width: auto;
    }
}
