.article-item, .featured-article-item {
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    margin: 15px;
    width: 350px;
    height: 215px;
    display: block; 
    text-decoration: none; 
    color: inherit; 
  }
  
  .featured-article-item {
    width: 730px;
    height: 460px;
  }
  
  .article-item img, .featured-article-item img {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: cover;
  }
  
  .article-item::after, .featured-article-item::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    z-index: 1;
  }
  
  .article-item h2, .featured-article-item h2 {
    position: absolute;
    margin: 0;
    color: var(--White, #FFF);
    font-size: 20px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.4px;
    z-index: 2;
  }
  
  .article-item p, .featured-article-item p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
    position: absolute;
    margin: 0;
    color: var(--White, #FFF);
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.28px;
    z-index: 2;
  }
  
  .article-item h2 {
    bottom: 66px;
    left: 16px;
    width: calc(100% - 32px);
  }
  
  .article-item p {
    bottom: 16px;
    left: 16px;
    padding-top: 16px;
    width: calc(100% - 32px);
  }
  
  .featured-article-item h2 {
    bottom: 86px;
    left: 36px;
    width: calc(100% - 72px);
  }
  
  .featured-article-item p {
    bottom: 36px;
    left: 36px;
    padding-top: 16px;
    width: calc(100% - 72px);
  }

  .article-item:hover, .featured-article-item:hover {
    cursor: pointer;
  }
  
  @media (max-width: 945px) {
    .featured-article-item {
      width: 350px;
      height: 215px;
    }
  
    .featured-article-item h2 {
      bottom: 66px;
      left: 16px;
      width: calc(100% - 32px);
    }
  
    .featured-article-item p {
      bottom: 16px;
      left: 16px;
      padding-top: 16px;
      width: calc(100% - 32px);
    }
  }
