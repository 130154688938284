body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --primary: #E8917B;
  --secondary: #849FD3;
  --primary-accent: #FFF9F8;
  --secondary-accent: ;
  --background: #FFFFFF;
  --text-primary: #232323;
  --text-secondary: #7D7D81;
}
