.blog-container {
    width: 80%;
    margin: 0 auto;
    max-width: 1140px;
}

.blog-title {
    text-align: center;
    margin-bottom: 30px;
}

.blog-posts-container {
    display: flex;
    justify-content: center;
}

.blog-posts {
    display: flex;
}

.stacked-blog-posts {
    display: flex;
    flex-direction: column;
}

.read-more-btn {
    font-size: 14px;
    font-weight: 600;
    border: none;
    padding: 16px 24px; 
    border-radius: 10px;
    margin: 0 auto;  
    margin-top: 50px;
    display: block;  
    background-color: #000;  
    color: var(--White, #FFF);  
    letter-spacing: -0.28px; 
}

.read-more-btn:hover {
    cursor: pointer;
}

.grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0px; 
}

@media (max-width: 1425px) {
    .blog-posts {
        flex-direction: column;
        align-items: center;
    }

    .stacked-blog-posts {
        flex-direction: row;
    }

    .grid-container {
        grid-template-columns: repeat(2, 1fr);
    }

    .blog-container {
        max-width: 760px;
    }
}

@media (max-width: 945px) {
    .stacked-blog-posts {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: center;
    }

    .grid-container {
        grid-template-columns: 1fr;
    }

    .blog-container {
        max-width: 380px;
    }
}
